






























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';
import VueI18n from 'vue-i18n';
import {authStoreActions, authStoreGetter} from '@/stores/auth.store';
import {userStoreActions, userStoreGetter} from '@/stores/user.store';

const authStore = namespace('auth');
const userStore = namespace('user');


@Component({
  components: {
    BaseDataCellComponent,
    UserManageComponent: () => import(
        /* webpackChunkName: "UserManageComponent" */
        '@/components/user/UserManage.component.vue'),
  },
})
export default class UserProfileComponent extends Vue {
  @Prop()
  public cellTitle!: VueI18n.TranslateResult | string;

  @Prop()
  public cellText!: VueI18n.TranslateResult | string;

  private editUserDialog: boolean = false;

  @userStore.Action(userStoreActions.LOAD_USER_ACTION)
  private loadUser!: (userId: string) => Promise<User>;

  @authStore.Getter(authStoreGetter.USER)
  private _user!: User;

  @userStore.Getter(userStoreGetter.ACTIVE_USER)
  private getActiveUser!: User;

  private loadingDone: boolean = false;

  get user(): User {
    return this.getActiveUser;
  }

  private editUser() {
    this.editUserDialog = true;
  }

  private async mounted() {
    try {
      await this.loadUser(this._user.id!);
    } finally {
      this.loadingDone = true;
    }
  }
}
